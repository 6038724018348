
import { textSearch } from "@/assets/ts/_utils";
import JsonExcel from "@/components/widgets/tables/JsonExcel.vue";
import { municipiosComarcas } from "@/core/data/municipiosProvinciasComarcas";
import { titulacionesTodas } from "@/core/data/titulaciones";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { SortProps, sortFn } from "@/core/helpers/sort";
import { ProfesionalEstado } from "@/enums";
import Page from "@/layout/Page.vue";
import { AuthModule, ProfesionalesListadoModule } from "@/store/modules";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField } from "vee-validate";
import { computed, defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useArrayPagination } from "vue-composable";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "profesionales-listado",
  components: { "download-excel": JsonExcel, Page, ErrorMessage },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const module = getModule(ProfesionalesListadoModule, store);
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;
    const csvLabels = {
      nombreComarca: t("csv.solicitudes.familias.comarca"),
      nombre: t("csv.listados.profesionales.nombre"),
      apellidos: t("csv.listados.profesionales.apellidos"),
      dni: t("csv.listados.profesionales.dni"),
      email: t("csv.listados.profesionales.email"),
      telefono: t("csv.listados.profesionales.telefono"),
      fechaNacimientoCsv: t("csv.listados.profesionales.fechaNacimiento"),
      direccion: t("csv.listados.profesionales.direccion"),
      localidad: t("csv.listados.profesionales.localidad"),
      provincia: t("csv.listados.profesionales.provincia"),
      titulacionText: t("csv.solicitudes.profesionales.titulacion"),
      titulacionOtras: t("csv.solicitudes.profesionales.titulacionOtras"),
      carnetConducir: t("csv.listados.profesionales.carnetConducir"),
      vehiculo: t("csv.listados.profesionales.vehiculo"),
    };
    const csvFields = [
      ...(!comarcaId ? ["nombreComarca"] : []),
      "nombre",
      "apellidos",
      "dni",
      "email",
      "telefono",
      "fechaNacimientoCsv",
      "direccion",
      "localidad",
      "provincia",
      "titulacionText",
      "titulacionOtras",
      "carnetConducir",
      "vehiculo",
    ];
    const csvName = "profesionalesListado" + new Date().getTime() + ".xlsx";

    const search = ref("");

    const sortNombreApellidos = function (a, b) {
      let primero = a.nombre + " " + a.apellidos;
      let segundo = b.nombre + " " + b.apellidos;
      if (primero > segundo) {
        return 1;
      }
      if (primero < segundo) {
        return -1;
      }
      return 0;
    };
    const cargaListado = ref(false);

    onBeforeMount(async () => {
      cargaListado.value = true;
      try {
        await module.fetchProfesionalesSolicitudModificacion(comarcaId);
        search.value = "";
        currentPage.value = 1;
      } catch (err) {
        Swal.fire({
          text: t("formularios.profesionalesListado.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaListado.value = false;
      }
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs(t("menu.profesionalesModificacion"), [
        t("menu.personas"),
      ]);
    });
    const handleEdit = (index, row) => {
      router.push(`profesionales-modificacion/${row.id}`);
    };

    const profesionales = computed(() => {
      return module.profesionalesListado?.map((ele) => {
        return {
          ...ele,
          fechaNacimientoCsv: new Date(ele.fechaNacimiento),
          titulacion: ele.titulacion?.map((titulacion) => {
            return (
              titulacionesTodas.find((ele) => ele.id === titulacion)?.text ??
              titulacion
            );
          }),
          nombreCompleto: [ele.nombre, ele.apellidos].join(" "),
          titulacionText:
            ele.titulacion
              ?.map((t) => titulacionesTodas.filter((a) => a.id == t))
              ?.map((t) => t.map((r) => r.text))
              .join(", ") || "",
          titulacionOtras: ele.titulacionOtras,
          nombreComarca: ele.comarca?.nombre,
        };
      });
    });

    const sortProps = ref<SortProps>({
      prop: "createdOn",
      order: "descending",
      method: null,
    });

    const filteredCollection = computed(() =>
      profesionales.value
        .slice()
        .sort(sortFn(sortProps))
        .filter((data) => {
          return (
            !search.value ||
            textSearch(!comarcaId ? data.nombreComarca : "", search.value) ||
            textSearch(data.nombreCompleto, search.value) ||
            textSearch(data.dni, search.value) ||
            textSearch(data.email, search.value) ||
            textSearch(data.telefono, search.value) ||
            textSearch(data.localidad, search.value)
          );
        })
    );

    const { value: localidad } = useField("localidad");

    const profesionalesListado = computed(() => {
      const filteredBySearch = (profesionales.value ?? [])
        .slice()
        .sort(sortFn(sortProps))
        .filter((data) => {
          return (
            !search.value ||
            textSearch(!comarcaId ? data.nombreComarca : "", search.value) ||
            textSearch(data.nombreCompleto, search.value) ||
            textSearch(data.dni, search.value) ||
            textSearch(data.email, search.value) ||
            textSearch(data.telefono, search.value) ||
            textSearch(data.localidad, search.value)
          );
        });

      if (localidad?.value === undefined || localidad?.value === "") {
        return filteredBySearch;
      } else {
        // Intersección de registros filtrados por búsqueda y por localidad
        return filteredBySearch?.filter((data) =>
          profesionales.value
            ?.filter((data) => data.localidad == localidad.value)
            ?.includes(data)
        );
      }
    });

    const municipiosComarca = computed(() => {
      if (!comarcaId) {
        return municipiosComarcas;
      } else {
        return municipiosComarcas.filter(
          (municipio) => municipio.comarcaId === comarcaId
        );
      }
    });
    const { result, currentPage, lastPage } = useArrayPagination(
      profesionalesListado,
      { pageSize: 100 }
    );

    return {
      profesionalesListado,
      filteredCollection,
      cargaListado,
      isAdmin: !comarcaId,
      csvLabels,
      csvFields,
      csvName,
      handleEdit,
      ProfesionalEstado,
      sortNombreApellidos,
      search,
      sortChange: ({ column, prop, order }) => {
        sortProps.value = { prop, order, method: column.sortMethod };
      },
      result,
      currentPage,
      lastPage,
      handleCurrentPageChange: (page: number) => (currentPage.value = page),
      municipiosComarca,
      localidad,
    };
  },
});
